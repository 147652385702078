import { get, post, deleteMethod, put } from '../../../../shared/api/apicaller';
import { entityPathFromUserType } from '../../../../shared/utils/user';
import { getSubdomain } from '../../../../shared/utils/helper';

import * as investmentConst from '../../../../shared/actions/const/investment.const';
import { buildUrlQuery } from '../../../../shared/utils/helper';

export const formatFilters = filters => {
  const filtersParsed = {};
  if (filters) {
    filters.forEach(o => (filtersParsed[o.key] = o.value));
  }
  return filtersParsed;
};

export const getTransmitsList = ({ page, perPage, orderBy, sort, locale, search, filters }) => {
  return get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/dashboard/investments/transmit-only`, {
      filter: JSON.stringify(formatFilters(filters)),
      display: 'full',
      order_by: orderBy,
      sort,
      locale,
      q: search,
      page,
      per_page: perPage,
    }),
    [],
  );
};

const getInvestments = (
  status,
  { userType, page, perPage, orderBy, sort, locale, search, filters },
  active_actor,
) => {
  const filtersParsed = {
    status: status,
    active_actor,
    ...formatFilters(filters),
  };
  return get(
    buildUrlQuery(
      `${process.env.REACT_APP_BACKEND_URL}/dashboard/${
        entityPathFromUserType[userType] || userType
      }/investments`,
      {
        filter: JSON.stringify(filtersParsed),
        display: 'full',
        order_by: orderBy,
        sort,
        locale,
        q: search,
        page,
        per_page: perPage,
      },
    ),
    [],
  );
};

const getInvestmentsExportCsv = (
  status,
  { orderBy, sort, locale, search, filters, creationMethod, totalItems },
) => {
  if (totalItems) {
    const filtersParsed = {
      status: status,
      ...formatFilters(filters),
    };
    return get(
      buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/api/investments/export/csv`, {
        creation_method: creationMethod.toLowerCase(),
        display: 'full',
        filter: JSON.stringify(filtersParsed),
        locale,
        order_by: orderBy,
        q: search,
        sort,
      }),
      [],
    );
  }
};

export const getInvestmentsExportForFund = (
  status,
  { orderBy, sort, locale, search, filters, creationMethod, totalItems },
) => {
  if (totalItems) {
    const filtersParsed = {
      status: status,
      ...formatFilters(filters),
    };
    return get(
      buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/investments/export/fund`, {
        filter: JSON.stringify(filtersParsed),
        display: 'full',
        order_by: orderBy,
        sort,
        locale,
        q: search,
        creation_method: creationMethod,
        total_items: totalItems,
      }),
      [],
    );
  }
};

export const getSharesValuationExport = ({ orderBy, sort, locale, search, filters, totalItems }) =>
  totalItems
    ? get(
        buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shares-valuation/export`, {
          filter: JSON.stringify(formatFilters(filters)),
          display: 'full',
          order_by: orderBy,
          sort,
          locale,
          q: search,
          total_items: totalItems,
        }),
        [],
      )
    : null;

export const getDashboardCounters = () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/investments/counters`;
  return get(url, []);
};

export const getInvestmentsInProgress = params =>
  getInvestments(investmentConst.STATUS_IN_PROGRESS, params);

export const getInvestmentsSuggested = (
  { userType, page, perPage, orderBy, sort, locale, search, filters },
  active_actor,
) => {
  const filtersParsed = {
    active_actor,
    ...formatFilters(filters),
  };
  return get(
    buildUrlQuery(
      `${process.env.REACT_APP_BACKEND_URL}/dashboard/${
        entityPathFromUserType[userType] || userType
      }/investments-suggested`,
      {
        filter: JSON.stringify(filtersParsed),
        display: 'full',
        order_by: orderBy,
        sort,
        locale,
        q: search,
        page,
        per_page: perPage,
      },
    ),
    [],
  );
};
export const getWallet = params => getInvestments(investmentConst.STATUS_CLOSED, params);

export const getOperationCancelledByUserType = params =>
  getInvestments(investmentConst.STATUS_CANCELLED, params);

export const getTransmitsListExport = params => getInvestmentsExportCsv(undefined, params);

export const getInvestmentsInProgressExport = params =>
  getInvestmentsExportCsv(investmentConst.STATUS_IN_PROGRESS, params);

export const getWalletExport = params =>
  getInvestmentsExportCsv(investmentConst.STATUS_CLOSED, params);

export const getOperationCancelledByUserTypeExport = params =>
  getInvestmentsExportCsv(investmentConst.STATUS_CANCELLED, params);

/**
 * Retourne la liste des commentaires liés à une opération
 * @param {number} operationId l'id MySQL de l'opération
 */
export const getComments = operationUuid => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${operationUuid}`, [404]);
};

/**
 * Permet de poster un commentaire lié à une opération sur le dashboard
 * @param {string} investmentLinkId l'investmentLink de l'investissement
 * @param {object} comment le commentaire de l'utilisateur à envoyer
 */
export const postComment = (investmentLinkId, comment) => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/operations/${investmentLinkId}/comment`,
    comment,
    [],
  );
};

/**
 *
 * @param {string} investmentLinkId ID of the investment
 * @param {array} documents query wished document(s)
 */
export const getDeclineDocumentsByInvestmentLinkId = (investmentLinkId, documents) => {
  if (typeof investmentLinkId !== 'string') {
    return Promise.reject(new Error('investmentLinkId is null'));
  }
  let queryDocument = '';
  if (Array.isArray(documents)) {
    documents.forEach(doc => {
      queryDocument += `document[]=${doc}&`;
    });
  } else {
    queryDocument = `document[]=${documents}`;
  }
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${investmentLinkId}/documents/declined?${queryDocument}`,
    [],
  );
};

/**
 *
 * @param {string} documentId id of the pdf document
 * @param {string} investmentLinkId ID of the investment
 */
export const getPdfFileLink = (documentId, investmentLinkId) => {
  // ABOUT mw-popup=O
  // BPCE required this parameter because their Viewer needs to open document as a pop up
  // instead of a new page (connexion reseted)
  return `${
    window.location.origin
  }/document/api/documents/${documentId}?type=file&origin=${getSubdomain()}&investment_link_id=${investmentLinkId}&mw-popup=O`;
};

/**
 * Retourne la liste des emails envoyés liés à une opération
 * @param {string} investmentLinkId l'investmentLink de l'investissement
 */
export const getEmailByLinkId = investmentLinkId => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/email/${investmentLinkId}/timeline`, [404]);
};

/**
 * Permet de renvoyer un email appelant à une action de l'investisseur ou de l'entreprise sur son parcours
 * @param {string} mailjetUuid L'uuid Mailjet de l'email concerné
 */
export const resendEmail = mailjetUuid => {
  return post(`${process.env.REACT_APP_BACKEND_URL}/api/email/${mailjetUuid}/resend`, {}, [404]);
};

/**
 * Retourne l'étape la plus avancée pour un investissement (ne pas confondre avec la dernière étape (getInvestmentLatestStep))
 * @param {string} investmentLinkId L'investmentLinkId de l'investissement concerné
 */
export const getInvestmentFurthestStep = investmentLinkId => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/steps/${investmentLinkId}/furthest`, [404]);
};

/**
 * Retourne la dernière étape enregistrée pour un investissement (ne pas confondre avec l'étape la plus avancée (getInvestmentFurthestStep))
 * @param {string} investmentLinkId L'investmentLinkId de l'investissement concerné
 */
export const getInvestmentLatestStep = investmentLinkId => {
  return get(`${process.env.REACT_APP_BACKEND_URL}/api/steps/${investmentLinkId}/latest`, [404]);
};

/**
 * Retourne une entreprise dans le cadre de la valorisation anuelle
 */
export const getSharesValuationFromSiren = company_siren => {
  return get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shares-valuation`, {
      filter: JSON.stringify({ company_siren }),
    }),
    [404],
  );
};

/**
 * Retourne la liste des entreprises dans le cadre de la valorisation annuelle pour le backoffice
 */

export const getBackofficeSharesValuationList = ({
  page,
  perPage,
  orderBy,
  sort,
  locale,
  search,
  filters,
}) =>
  get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shares-valuation`, {
      filter: JSON.stringify(formatFilters(filters)),
      display: 'full',
      order_by: orderBy,
      sort,
      locale,
      q: search,
      page,
      per_page: perPage,
    }),
    [404],
  );

/**
 * Envoi la liste des entreprises sélectionnées par le backoffice pour leur envoyer un email dans le cadre des valorisations annuelles
 */
export const postBackofficeSharesValuationList = () =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation`, {}, [404]);

/**
 * Envoi au back-end la mise à jour des valeurs d'une entreprise
 * @param {object} body Objet reprenant toutes les informations de l'entreprise dont les valeurs qu'il a mises à jour avec la date effective
 */
export const postCompanyUpdatedSharesValuation = body =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/prices`, body, [404]);

/**
 * Envoi au back-end l'information de la supression du document, afin de délier celui-ci de la table share-valuation
 * @param {string} companyId id of the company
 * @param {string} documentId id of the share valuation document to delete
 */
export const deleteDocumentSharesValuations = (companyId, documentId) =>
  deleteMethod(
    `${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/${companyId}/documents/${documentId}`,
    [404],
  );

/**
 * Récupère le détail des valorisations annuelles pour une entreprise donnée
 * @param {string} companyId uuid of the company
 */
export const getSharesValuationDetailsByCompanyId = companyId =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/${companyId}`, [404]);

export const getSharesValuationHistoryDetailsByCompanyId = (
  companyId,
  { page, perPage, orderBy, sort, locale },
) =>
  get(
    buildUrlQuery(
      `${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/${companyId}/history`,
      {
        order_by: orderBy,
        sort,
        locale,
        page,
        per_page: perPage,
      },
    ),
    [404],
  );
/**
 * Permet l'export CSV des valeurs d'une entreprise (tab valorisations annuelles)
 */
export const getSharesValuationDetailsByCompanyIdExport = ({ companyId, totalItems }) =>
  totalItems
    ? get(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shares-valuation/${companyId}/export`, [])
    : null;

/**
 * Retourne le registre (onglet Actionnariat) d'un utilisateur entreprise
 */
export const getCompanyShareholdingList = ({
  page,
  perPage,
  orderBy,
  sort,
  locale,
  search,
  filters,
}) =>
  get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/api/shareholding`, {
      filter: JSON.stringify(formatFilters(filters)),
      display: 'full',
      page,
      per_page: perPage,
      order_by: orderBy,
      sort,
      locale,
      q: search,
    }),
    [404],
  );

/**
 * Retourne le registre (onglet Actionnariat) d'un utilisateur entreprise
 */
export const retrieveFundList = ({ page, perPage, orderBy, sort, locale, search, filters }) =>
  get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/api/fund-summary`, {
      filters: JSON.stringify(formatFilters(filters)),
      sortBy: orderBy,
      sortOrder: sort,
      locale,
      searchQuery: search,
      page,
      limit: perPage,
    }),
    [404],
  );

/**
 * Permet l'export CSV de l'onglet Actionnariat (dashboard entreprise uniquement)
 */
export const getShareholdingExport = ({ orderBy, sort, locale, search, filters, totalItems }) =>
  totalItems
    ? get(
        buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shareholding/export`, {
          filter: JSON.stringify(formatFilters(filters)),
          display: 'full',
          order_by: orderBy,
          sort,
          locale,
          q: search,
          total_items: totalItems,
        }),
        [],
      )
    : null;

export const exportFundManagement = ({ page, perPage, orderBy, sort, locale, search, filters }) =>
  get(
    buildUrlQuery(`${process.env.REACT_APP_BACKEND_URL}/api/fund-summary/export/csv`, {
      filters: JSON.stringify(formatFilters(filters)),
      sortBy: orderBy,
      sortOrder: sort,
      locale,
      searchQuery: search,
      page,
      limit: perPage,
    }),
    [404],
  );

/**
 * Récupère le détail du portefeuille d'un investisseur au clic sur une ligne dans l'onglet Actionnariat (dashboard entreprise uniquement)
 * @param {string} investorId uuid of the investor
 */
export const getShareholdingDetailsByInvestorId = investorId =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/shareholding/${investorId}`, [404]);

/**
 * Permet l'export CSV du portefeuille d'un investisseur au clic sur une ligne dans l'onglet Actionnariat (dashboard entreprise uniquement)
 */
export const getShareholdingInvestorWalletExport = ({ investorId, totalItems }) =>
  totalItems
    ? get(`${process.env.REACT_APP_BACKEND_URL}/dashboard/shareholding/${investorId}/export`, [])
    : null;

/**
 * Envoi d'une insertion manuelle d'une entreprise pour un portefeuille investisseur pour l'onglet Actionnariat
 * @param {object} body Objet reprenant toutes les informations renseignées par l'entreprise pour une insertion manuelle
 */
export const postCompanyShareholdingManualInsertion = body =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/shareholding`, body, [404]);

/**
 * Suppression d'une insertion manuelle par l'entreprise dans le portefeuille d'un investisseur dans l'onglet Actionnariat
 * @param {string} uuid uuid of the shareholding row in DB COMMON > shareholding
 * @param posId id de la position
 */
export const deleteCompanyShareholdingManualInsertion = (uuid, posId) =>
  deleteMethod(`${process.env.REACT_APP_BACKEND_URL}/api/shareholding/${uuid}?posId=${posId}`, {}, [
    404,
  ]);

/**
 * Récupère le détail des positions des investisseurs pour une entreprise au clic sur la colonne Confirmation des positions de l'onglet Valorisations (dashboard entreprise uniquement)
 * @param {string} companyId uuid of the company
 * @param page
 * @param perPage
 * @param orderBy
 * @param sort
 * @param locale
 * @param search
 */
export const getPositionsDetailsByCompanyId = ({
  companyId,
  page,
  perPage,
  orderBy,
  sort,
  locale,
  search,
}) =>
  get(
    buildUrlQuery(
      `${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/positions/${companyId}`,
      {
        display: 'full',
        page,
        per_page: perPage,
        order_by: orderBy,
        sort,
        locale,
        q: search,
      },
    ),
    [404],
  );

export const getPositionHistoryDetailsByCompanyId = (
  companyId,
  { page, perPage, orderBy, sort, locale },
) =>
  get(
    buildUrlQuery(
      `${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/positions/${companyId}/history`,
      {
        order_by: orderBy,
        sort,
        locale,
        page,
        per_page: perPage,
      },
    ),
    [404],
  );

/**
 * Envoi au back-end la mise à jour des positions des investisseurs d'une entreprise
 * @param {object} body Objet reprenant toutes les positions des actionnaires mises à jour par l'entreprise
 */
export const postCompanyUpdatedPositions = body =>
  post(`${process.env.REACT_APP_BACKEND_URL}/api/shares-valuation/positions`, body, [404]);

/**
 * Permet l'export CSV des positions des actionnaires dans l'onglet valorisations annuelles colonne Positions (dashboard entreprise uniquement)
 */
export const getPositionsDetailsExport = ({
  orderBy,
  sort,
  locale,
  search,
  companyId,
  totalItems,
}) =>
  totalItems
    ? get(
        buildUrlQuery(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/positions/${companyId}/export`,
          {
            display: 'full',
            order_by: orderBy,
            sort,
            locale,
            q: search,
            total_items: totalItems,
          },
        ),
        [],
      )
    : null;

/**
 *
 * @param {object} body Objet reprenant toutes les positions des actionnaires mises à jour par l'entreprise
 */
export const putUpdateOperationFlagStatus = body =>
  put(
    `${process.env.REACT_APP_BACKEND_URL}/api/investments/${body.operationId}/update-flag`,
    body,
    [404],
  );

/**
 *
 * @param investmentDraftLinkId id of draft op
 * @param {object} body Objet reprenant toutes les infos de l'opération brouillon à mettre à jour
 */
export const putUpdateSuggestedOperation = async (investmentDraftLinkId, body) =>
  put(`${process.env.REACT_APP_BACKEND_URL}/draft/operations/${investmentDraftLinkId}`, body, [
    404,
  ]);

export const getInvestorSharesValuationPositionList = async () => {
  const res = await get(`${process.env.REACT_APP_BACKEND_URL}/shares-valuation/positions`);
  return res;
};

export const getInvestorSharesValuationPositionByCompanyForInvestor = async companyUUID => {
  const res = await get(
    `${process.env.REACT_APP_BACKEND_URL}/shares-valuation/positions/${companyUUID}/investor`,
  );
  return res;
};
