import dayjs from 'dayjs';
import * as libphonenumber from 'libphonenumber-js';
import { isEmailAddressValid } from './helper';
import { formatFloatValue } from './CatalizrFormUtils';
import { INT_MAX_LIMIT } from './CatalizrConstants';
import defaultShouldAsyncValidate from 'redux-form/lib/defaultShouldAsyncValidate';
import { checkIsin } from './isin-utils';
import { isUserCompany } from './JourneyUtils';
import { getStore } from '../../store';

export const onKeyDownForInteger = evt => {
  const isValid = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
  ].includes(evt.key);
  if (isValid === false && evt.metaKey === false) {
    evt.preventDefault();
  }
  return isValid;
};

export const onKeyDownForDecimal = evt => {
  const isValid = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ',',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
  ].includes(evt.key);
  if (isValid === false) {
    evt.preventDefault();
  }
  return isValid;
};

export const required = value => {
  if (Array.isArray(value)) {
    return value.length ? undefined : 'Veuillez selectionne une valeur';
  }
  return value ? undefined : 'Veuillez remplir ce champ';
};

export const maxTitleAmountAllowed = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }
  const number = formatFloatValue(value);
  return number <= INT_MAX_LIMIT ? undefined : 'Valeur maximum autorisée atteinte';
};

export const maxAmountUnitAllowed = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }
  const number = formatFloatValue(value);
  return number <= Number.MAX_SAFE_INTEGER ? undefined : 'Valeur maximum autorisée atteinte';
};

export const requiredOrEqualZero = value =>
  value || value === 0 ? undefined : 'Veuillez remplir ce champ';

export const shouldNotEqualZero = value => {
  if (typeof value === 'number') {
    return value === 0 ? 'Ce champ ne peut pas être égal à zéro' : undefined;
  }
  if (typeof value === 'string') {
    const number = formatFloatValue(value);
    return number === 0 ? 'Ce champ ne peut pas être égal à zéro' : undefined;
  }
  return undefined;
};

export const maxLength = max => value => {
  if (typeof value !== 'string') {
    return undefined;
  }
  return value.length > max ? `Saisissez au maximum ${max} caractères` : undefined;
};

export const minLength = min => value => {
  if (typeof value !== 'string') {
    return undefined;
  }
  return value.length < min ? `Saisissez au minimum ${min} caractères` : undefined;
};

export const length = size => value => {
  if (typeof value !== 'string') {
    return undefined;
  }
  return value.length !== size ? `Saisissez ${size} caractères` : undefined;
};

export const nameLength = value => maxLength(60)(value);

export const cityLength = value => maxLength(60)(value);

export const legalFormLength = value => maxLength(131)(value);

export const addressLength = value => maxLength(150)(value);

/**
 * Export for sync validator, please also check async validator below
 */
export const emailValid = email => {
  if (email) {
    return isEmailAddressValid(email)
      ? undefined
      : 'Veuillez remplir ce champ avec une adresse email valide';
  }
  return undefined;
};

export const phone = value =>
  value && !libphonenumber.isValidNumber(value)
    ? 'Veuillez entrer un numéro de téléphone valide'
    : undefined;

export const accountNumber = value => {
  if (typeof value !== 'string') {
    return undefined;
  }
  return value.length !== 11 ? 'Veuillez renseigner un numéro de compte valide' : undefined;
};

export const positif = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }
  let number = formatFloatValue(value);
  return number < 0 ? 'Veuillez renseigner un nombre positif' : undefined;
};

export const percent = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }
  let number = formatFloatValue(value);
  return number < 0 || number > 100
    ? 'Veuillez renseigner un pourcentage entre 0 et 100'
    : undefined;
};

export const decimalNumber = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined;
  }
  return !/^[0-9]+[.|,]?[0-9]{0,2}$/.test(value)
    ? 'Veuillez renseigner un nombre à 2 décimales maximum'
    : undefined;
};

export const checkDate = value => {
  if (dayjs.isDayjs(value)) {
    return dayjs(value, 'DD/MM/YYYY', true).isValid()
      ? undefined
      : 'Veuillez entrer une date valide';
  }
  return undefined;
};

// check if the ownership date is valid (<61 days compared to current date)
export const isOwnerShipValidityDate = ownership_date => {
  if (typeof ownership_date === 'undefined' || ownership_date === null) {
    return false;
  }
  // format and convert dates into a moment object to apply diff function
  const nowDate = dayjs(dayjs().format('DD-MM-YYYY'), 'DD-MM-YYYY');
  const ownershipdate = dayjs(dayjs(ownership_date).format('DD-MM-YYYY'), 'DD-MM-YYYY');
  const duration = nowDate.diff(ownershipdate, 'months', true);
  return duration <= 2;
};

export const alphabetical = value =>
  value && /[0-9]/i.test(value) ? 'Saisissez uniquement des lettres' : undefined;

export const isinValid = isin => {
  if (typeof isin !== 'string') {
    return undefined;
  }
  if (checkIsin(isin)) {
    return undefined;
  }
  return 'Le code ISIN doit commencer par deux lettres suivies de 9 chiffres et/ou lettres suivies de 1 chiffre';
};

// no validator function below

/*
    Scroll to first error on submit form with redux form
  */
export const scrollToInvalid = errors => {
  if (errors) {
    // key1
    let key1 = Object.keys(errors)[0];

    // key2
    let key2 = '';
    let key2Index = '';
    if (Array.isArray(errors[key1])) {
      key2Index = errors[key1].findIndex(value => value);
      key2 = `[${key2Index}]`;
    } else {
      key2Index = Object.keys(errors[key1])[0];
      key2 = `.${key2Index}`;
    }

    // key3
    let key3 = '';
    if (typeof errors[key1][key2Index] !== 'string') {
      key3 = `.${Object.keys(errors[key1][key2Index])[0]}`;
    }

    const elementName = `${key1}${key2}${key3}`;
    // HACK for React-picky which doesn't allow to set property name, only id
    const elementToFind = document.getElementsByName(elementName)[0]
      ? document.getElementsByName(elementName)[0]
      : document.getElementById(elementName);
    if (elementToFind) {
      window.scrollTo(0, window.pageYOffset + (elementToFind.getBoundingClientRect().top - 50));
    }
  }
};

/**
 * async validation not always activated when sync validation return
 * Form-redux has a issue on this, see link below
 * @see https://github.com/redux-form/redux-form/issues/3944#issuecomment-1236699349
 */
export const shouldAsyncInvestmentValidate = params => {
  return defaultShouldAsyncValidate({ ...params, syncValidationPasses: true });
};

export const defaultAsyncValidate = () => {
  return Promise.resolve();
};

export const isDraftInvestment = () => getStore().getState().common.isDraftMode;

/**
 * Checks whether a given value is defined and returns it if valid. Otherwise, it returns `undefined`.
 * @param {string} value the crowdfunding transfer reference field.
 * @returns {string | undefined} Returns the value if it defined and valid, otherwise `undefined`.
 */
export const validateTransferRefField = value => {
  const min = 3;
  const max = 140;
  const isDraftMode = isDraftInvestment() ? true : false;
  /* eslint-disable no-useless-escape */
  const regex = /^[A-Za-z0-9\s–.\/()'+?:,]+$/;

  if (!value) {
    return isUserCompany() && !isDraftMode ? 'Veuillez remplir ce champ' : undefined;
  }

  if (typeof value !== 'string') {
    return 'Saisie incorrecte: une chaine de caractère est attendue.';
  }

  if (value.length < min) {
    return `Le libellé de virement doit contenir au moins ${min} caractères.`;
  }

  if (value.length > max) {
    return `Le libellé de virement ne doit pas dépasser ${max} caractères.`;
  }

  if (!regex.test(value)) {
    return 'Saisie incorrecte: veuillez utiliser uniquement les lettres, nombres, espaces, tirets, slashs, et points.';
  }
  return undefined;
};
